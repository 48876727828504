import React from 'react';
import '../../App.css';

export default function SignUp() {
    return (
        <div>
            <h1 className='sign-up'>SIGN UP</h1>
        </div>
    )
}
