import React from 'react';
import '../../App.css';

export default function Products() {
    return (
        <div>
            <h1 className='products'>PRODUCTS</h1>
        </div>
    )
}

