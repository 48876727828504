import React from 'react';
import Navbar from './components/Navbar';
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import Products from './components/pages/Products';
import Services from './components/pages/Services';
import SignUP from './components/pages/SignUp';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exect element={<Home />} /> 
          <Route path='/services' element={<Services />} /> 
          <Route path='/products' element={<Products />} /> 
           <Route path='/sign-up' element={<SignUP />} />      
        </Routes>
      </Router>      
    </>
  );
}

export default App;
