import React from 'react';
import { Button } from './Button';
import './HeroSection.css';
import '../App.css';

function HeroSection() {
    return (
        <div className='hero-container'>
            <video   autoPlay='autoplay' loop='true' muted >
                <source src='../../public/videos/video-1.mp4' type="video/mp4" />
            </video>
            <h1>ADVANTURE AWAIT</h1>
            <p>what are you waiting for?</p>
            <div className='hero-btns'>
                <Button className='btn' buttonStyle='btn--outline' buttonSize='btn--large'>
                GET STARTED
                </Button>
                <Button className='btn' buttonStyle='btn--primary' buttonSize='btn--large'>
                WATCH TRAILER <i className='far fa-play-circle' />
                </Button>            
            </div>  
        </div>
    );
}

export default HeroSection
